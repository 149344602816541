export default class EnumMiddlewares {
  GENERATE_COMPONENT_UUID = '{{GENERATE_COMPONENT_UUID}}';
  GENERATE_NEW_UUID = '{{GENERATE_NEW_UUID}}';
  
  constructor() {
      this.items = [
          {
              "id": 1,
              "component": "Webhook",
              "i18key": "",
              "helper": undefined,
              "active": false,
              "hasChildren": false,
          },
          {
              "id": 2,
              "component": "middleware-json-requester-sidebar",
              "i18key": "json_requester",
              "icon": "braces",
              "helper": undefined,
              "active": true,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "requester",
              "docsLink": "https://doc-fiqon.notion.site/JSON-Requester-88822200ee514ebfb078a57ed2fa4cb0",
              "hasChildren": false,
          },
          {
              "id": 3,
              "component": "",
              "i18key": "custom",
              "helper": undefined,
              "active": true,
              "icon": "tools",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "utility",
              "hasChildren": false,
              "docsLink": "https://doc-fiqon.notion.site/Custom-Middleware-25b626ed57de46f288ea8dec5fc83c65",
          },
          {
              "id": 4,
              "component": "middleware-denied-sidebar",
              "i18key": "denied_list",
              "helper": undefined,
              "active": false,
              "icon": "shield-slash",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "connectivity",
              "hasChildren": false,
          },
          {
              "id": 5,
              "component": "middleware-allowed-sidebar",
              "i18key": "allowed_list",
              "helper": undefined,
              "active": false,
              "icon": "shield-check",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "connectivity",
              "hasChildren": false,
          },
          {
              "id": 6,
              "component": "middleware-propagator-sidebar",
              "i18key": "propagator",
              "helper": undefined,
              "active": true,
              "icon": "broadcast-pin",
              "has_child": false,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "internal",
              "hasChildren": false,
              "docsLink": "https://doc-fiqon.notion.site/Propagator-d6441059d395454a9f0efe1b95d4f674",
          },
          {
              "id": 7,
              "component": "middleware-logger",
              "i18key": "logger",
              "helper": undefined,
              "active": false,
              "category": "internal",
              "hasChildren": false,
          },
          {
              "id": 8,
              "component": "middleware-mongo",
              "icon": "server",
              "i18key": "database",
              "helper": undefined,
              "active": false,
              "hasChildren": false,
          },
          {
              "id": 9,
              "component": "middleware-xml-requester-sidebar",
              "i18key": "xml_requester",
              "icon": "code-slash",
              "helper": undefined,
              "active": true,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "requester",
              "hasChildren": false,
              "docsLink": "https://doc-fiqon.notion.site/XML-Requester-eaaba3747d374bc6bf0db08b6d2517b8",
          },
          {
              "id": 10,
              "component": "middleware-default-requester-sidebar",
              "i18key": "requester",
              "icon": "cursor",
              "helper": undefined,
              "active": true,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "requester",
              "hasChildren": false,
              "docsLink": "https://doc-fiqon.notion.site/Raw-Requester-9521d3456d2e4e1b99055c129ad22f15",
          },
          {
              "id": 11,
              "component": undefined,
              "i18key": "checkpoint",
              "helper": undefined,
              "active": true,
              "icon": "flag",
              "no_agents": true,
              "category": "internal",
              "hasChildren": false,
          },
          {
              "id": 12,
              "component": 'middleware-for-sidebar',
              "i18key": "for",
              "icon": "arrow-repeat",
              "helper": undefined,
              "active": true,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "has_child": true,
              "end_block": {
                  "type_id": 13,
              },
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "loop",
              "hasChildren": true,
              "docsLink": "https://doc-fiqon.notion.site/FOR-3f612ede708c46ddb14237f65abe3420",
          },
          {
              "id": 13,
              "component": undefined,
              "i18key": "end_for",
              "helper": undefined,
              "active": false,
              "icon": "arrow90deg-up",
              "no_agents": true,
              "is_end_block": true,
              "category": "loop",
              "hasChildren": false,
          },
          {
              "id": 14,
              "component": 'middleware-while-sidebar',
              "i18key": "while",
              "icon": "arrow-repeat",
              "helper": undefined,
              "active": true,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "has_child": true,
              "end_block": {
                  "type_id": 15,
              },
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "loop",
              "hasChildren": true,
              "docsLink": "https://doc-fiqon.notion.site/While-4e421ea5320c48cea7c150a357b628b0",
          },
          {
              "id": 15,
              "component": undefined,
              "i18key": "end_while",
              "helper": undefined,
              "active": false,
              "icon": "arrow-repeat",
              "no_agents": true,
              "is_end_block": true,
              "category": "loop",
              "hasChildren": false,
          },
          {
              "id": 16,
              "component": 'middleware-if-sidebar',
              "i18key": "if",
              "helper": undefined,
              "active": true,
              "icon": "diagram2",
              "no_agents": false,
              "has_child": true,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "end_block": {
                  "type_id": 18,
              },
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "logic",
              "hasChildren": true,
          },
          {
              "id": 17,
              "component": undefined,
              "i18key": "else",
              "helper": undefined,
              "active": false,
              "icon": "diagram2",
              "no_agents": true,
              "has_child": true,
              "category": "logic",
              "hasChildren": false,
          },
          {
              "id": 18,
              "component": undefined,
              "i18key": "end_if",
              "helper": undefined,
              "active": false,
              "icon": "diagram2",
              "no_agents": true,
              "is_end_block": true,
              "category": "logic",
              "hasChildren": false,
          },
          {
              "id": 19,
              "component": "middleware-ftp-file-retriever-sidebar",
              "i18key": "ftp_retrieve_file",
              "helper": undefined,
              "active": true,
              "icon": "file-earmark-arrow-down",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "ftp",
              "hasChildren": false,
          },
          {
              "id": 20,
              "component": "middleware-ftp-store-file-sidebar",
              "i18key": "ftp_store_file",
              "helper": undefined,
              "active": true,
              "icon": "file-earmark-arrow-up",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "ftp",
              "hasChildren": false,
          },
          {
              "id": 21,
              "component": "middleware-ftp-file-rename-sidebar",
              "i18key": "ftp_rename_file",
              "helper": undefined,
              "active": true,
              "icon": "file-earmark-text",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "ftp",
              "hasChildren": false,
          },
          {
              "id": 22,
              "component": "middleware-ftp-list-files-sidebar",
              "i18key": "ftp_list_files",
              "helper": undefined,
              "active": true,
              "icon": "files",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "ftp",
              "hasChildren": false,
          },
          {
              "id": 23,
              "component": 'middleware-continue-sidebar',
              "i18key": "continue",
              "helper": undefined,
              "active": true,
              "icon": "play-circle",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "loop",
              "hasChildren": false,
          },
          {
              "id": 24,
              "component": undefined,
              "i18key": "break",
              "helper": undefined,
              "active": true,
              "icon": "slash-circle",
              "no_agents": true,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "utility",
              "hasChildren": false,
          },
          {
              "id": 25,
              "component": 'middleware-sql-query-sidebar',
              "i18key": "mysql_query",
              "helper": undefined,
              "active": true,
              "icon": "server",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
              "docsLink": "https://doc-fiqon.notion.site/MYSQL-Query-30a686c409624f7eb91de9bf4faa4163",
          },
          {
              "id": 26,
              "component": 'middleware-sql-insert-sidebar',
              "i18key": "mysql_insert",
              "helper": undefined,
              "active": false,
              "icon": "server",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 27,
              "component": 'middleware-sql-delete-sidebar',
              "i18key": "mysql_delete",
              "helper": undefined,
              "active": false,
              "icon": "server",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 28,
              "component": 'middleware-sql-select-sidebar',
              "i18key": "mysql_select",
              "helper": undefined,
              "active": false,
              "icon": "server",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 29,
              "component": 'middleware-sql-update-sidebar',
              "i18key": "mysql_update",
              "helper": undefined,
              "active": false,
              "icon": "server",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 30,
              "component": 'middleware-url-encoded-requester-sidebar',
              "i18key": "url_encoded_requester",
              "helper": undefined,
              "active": true,
              "icon": "globe2",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "requester",
              "hasChildren": false,
              "docsLink": "https://doc-fiqon.notion.site/URL-Encoded-Requester-58e5d8325cde4e90a7ad28403621d3b8",
          },
          {
              "id": 31,
              "component": 'middleware-form-data-requester-sidebar',
              "i18key": "form_data_requester",
              "helper": undefined,
              "active": true,
              "icon": "card-checklist",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "requester",
              "hasChildren": false,
              "docsLink": "https://doc-fiqon.notion.site/Form-Data-Requester-e75c74c5c1854830a932599a5e476f5e",
          },
          {
              "id": 32,
              "component": 'middleware-mongo-delete-sidebar',
              "i18key": "mongo_delete",
              "helper": undefined,
              "active": true,
              "icon": "server",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 33,
              "component": 'middleware-mongo-insert-sidebar',
              "i18key": "mongo_insert",
              "helper": undefined,
              "active": true,
              "icon": "server",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 34,
              "component": 'middleware-mongo-find-sidebar',
              "i18key": "mongo_find",
              "helper": undefined,
              "active": true,
              "icon": "server",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 35,
              "component": 'middleware-mongo-update-sidebar',
              "i18key": "mongo_update",
              "helper": undefined,
              "active": true,
              "icon": "server",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 36,
              "component": 'middleware-soap-requester-sidebar',
              "i18key": "soap_requester",
              "helper": undefined,
              "active": true,
              "icon": "cursor",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "requester",
              "hasChildren": false,
              "docsLink": "https://doc-fiqon.notion.site/SOAP-Requester-99e3603c1e804e73ad1531dca2c85e07",
          },
          {
              "id": 37,
              "component": 'undefined',
              "i18key": "switch",
              "helper": undefined,
              "active": false,
              "icon": "cursor",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "logic",
              "hasChildren": true,
          },
          {   
              "id": 38,
              "component": 'undefined',
              "i18key": "case",
              "helper": undefined,
              "active": false,
              "icon": "cursor",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "logic",
              "hasChildren": true,
          },
          {
              "id": 39,
              "component": 'undefined',
              "i18key": "case_default",
              "helper": undefined,
              "active": false,
              "icon": "cursor",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "logic",
              "hasChildren": true,
          },
          {
              "id": 40,
              "component": 'middleware-foreach-sidebar',
              "i18key": "foreach",
              "helper": undefined,
              "active": true,
              "icon": "arrow-repeat",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "has_child": true,
              "end_block": {
                  "type_id": 45,
              },
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "loop",
              "hasChildren": true,
          },
          {
              "id": 41,
              "component": "middleware-stop",
              "i18key": "throw",
              "helper": undefined,
              "active": true,
              "icon": "exclamation-circle",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "internal",
              "hasChildren": false,
          },
          {   
              "id": 42,
              "component": 'undefined',
              "i18key": "end_switch",
              "helper": undefined,
              "active": false,
              "icon": "arrow90deg-up",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "logic",
              "hasChildren": false,
          },
          {
              "id": 43,
              "component": 'undefined',
              "i18key": "end_case",
              "helper": undefined,
              "active": false,
              "icon": "arrow90deg-up",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "logic",
              "hasChildren": false,
          },
          {
              "id": 44,
              "component": 'undefined',
              "i18key": "end_case_default",
              "helper": undefined,
              "active": false,
              "icon": "arrow90deg-up",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "logic",
              "hasChildren": false,
          },
          {
              "id": 45,
              "component": undefined,
              "i18key": "end_foreach",
              "helper": undefined,
              "active": false,
              "icon": "arrow-repeat",
              "no_agents": true,
              "is_end_block": true,
              "category": "loop",
              "hasChildren": false,
          },
          {
              "id": 46,
              "component": "middleware-google-sheets",
              "i18key": "google_sheets",
              "helper": undefined,
              "active": true,
              "icon": "table",
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "connectivity",
              "hasChildren": false,
          },
          {
              "id": 47,
              "component": "middleware-postgres-sql-query",
              "i18key": "postgresql_query",
              "icon": "server",
              "helper": undefined,
              "active": true,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 48,
              "component": "middleware-postgres-sql-delete",
              "i18key": "postgresql_delete",
              "icon": "server",
              "helper": undefined,
              "active": false,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 49,
              "component": "middleware-postgres-sql-select",
              "i18key": "postgresql_select",
              "icon": "server",
              "helper": undefined,
              "active": false,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 50,
              "component": "middleware-postgres-sql-insert",
              "i18key": "postgresql_insert",
              "icon": "server",
              "helper": undefined,
              "active": false,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 51,
              "component": "middleware-postgres-sql-update",
              "i18key": "postgresql_update",
              "helper": undefined,
              "icon": "server",
              "active": false,
              "props": [
                  { prop: "middlewareID", value: 'id' },
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 52,
              "component": "middleware-ftp-delete-file-sidebar",
              "i18key": "ftp_delete_file",
              "helper": undefined,
              "active": true,
              "icon": "file-earmark-arrow-down",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "ftp",
              "hasChildren": false,
          },
          {
            "id": 53,
            "component": "middleware-xml-signer-sidebar",
            "i18key": "xml_signer",
            "icon": "code-slash",
            "helper": undefined,
            "active": true,
            "props": [
                { prop: "middlewareID", value: 'id' },
            ],
            "events": {
                "show": "bv::toggle::collapse",
                "hide": "bv::toggle::collapse",
            },
            "category": "editor",
            "hasChildren": false,
          },
          {
            "id": 54,
            "component": 'middleware-firebird-query-sidebar',
            "i18key": "firebird_query",
            "helper": undefined,
            "active": true,
            "icon": "server",
            "no_agents": false,
            "is_end_block": false,
            "props": [
                { prop: "middlewareID", value: "id" }
            ],
            "events": {
                "show": "bv::toggle::collapse",
                "hide": "bv::toggle::collapse",
            },
            "category": "connectivity",
            "hasChildren": false,
          },
          {
            "id": 55,
            "component": 'middleware-encrypt',
            "i18key": "encrypt",
            "helper": undefined,
            "active": true,
            "icon": "lock-fill",
            "no_agents": false,
            "is_end_block": false,
            "props": [
                { prop: "middlewareID", value: "id" }
            ],
            "events": {
                "show": "bv::toggle::collapse",
                "hide": "bv::toggle::collapse",
            },
            "category": "utility",
            "hasChildren": false,
          },
          {
            "id": 56,
            "component": 'middleware-decrypt',
            "i18key": "decrypt",
            "helper": undefined,
            "active": true,
            "icon": "unlock-fill",
            "no_agents": false,
            "is_end_block": false,
            "props": [
                { prop: "middlewareID", value: "id" }
            ],
            "events": {
                "show": "bv::toggle::collapse",
                "hide": "bv::toggle::collapse",
            },
            "category": "utility",
            "hasChildren": false,
          },
          {
              "id": 57,
              "component": 'middleware-sql-server-query-sidebar',
              "i18key": "sql_server_query",
              "helper": undefined,
              "active": true,
              "icon": "server",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
              "id": 58,
              "component": 'middleware-sybase-query-sidebar',
              "i18key": "sybase_query",
              "helper": undefined,
              "active": true,
              "icon": "server",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "database",
              "hasChildren": false,
          },
          {
            "id": 59,
            "component": 'middleware-wait-sidebar',
            "i18key": "wait",
            "helper": undefined,
            "active": true,
            "icon": "hourglass-split",
            "no_agents": false,
            "is_end_block": false,
            "props": [
                { prop: "middlewareID", value: "id" }
            ],
            "events": {
                "show": "bv::toggle::collapse",
                "hide": "bv::toggle::collapse",
            },
            "category": "utility",
            "hasChildren": false,
          },
          {
            "id": 60,
            "component": 'middleware-oracle-query-sidebar',
            "i18key": "oracle_query",
            "helper": undefined,
            "active": true,
            "icon": "server",
            "no_agents": false,
            "is_end_block": false,
            "props": [
                { prop: "middlewareID", value: "id" }
            ],
            "events": {
                "show": "bv::toggle::collapse",
                "hide": "bv::toggle::collapse",
            },
            "category": "database",
            "hasChildren": false,
          },
          {
              "id": 8000,
              "component": 'middleware-html-editor-sidebar',
              "i18key": "html_editor",
              "helper": undefined,
              "active": true,
              "icon": "file-earmark-code",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "editor",
              "hasChildren": false,
          },
          {
              "id": 8001,
              "component": 'middleware-code-editor-sidebar',
              "i18key": "code_editor",
              "helper": undefined,
              "active": true,
              "icon": "file-earmark-binary",
              "no_agents": false,
              "is_end_block": false,
              "props": [
                  { prop: "middlewareID", value: "id" }
              ],
              "events": {
                  "show": "bv::toggle::collapse",
                  "hide": "bv::toggle::collapse",
              },
              "category": "editor",
              "hasChildren": false,
          },
      ];
  }

  getEndblocks(){
    let r = []
    this.items.forEach((itm)=>{
      if ( itm.is_end_block ){
        r.push(itm)
      }
    })
    return r
  }
  getStartBlocks(){
    let r = []
    this.items.forEach((itm)=>{
      if ( itm.hasChildren ){
        r.push(itm)
      }
    })
    return r
  }
}