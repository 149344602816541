<template>
  <json-viewer
    v-if="parsedData !== undefined"
    :value="parsedData"
    :expand-depth="15"
    :copyable="copyable"
    :show-array-index="false"
    :show-double-quotes="true"
    :theme="theme"
    :class="[bgColor ,' custom-pre-component']"
    :style="`
      max-height: ${maxHeight};
      min-height: ${minHeight};
    `"
  />
  <div 
    v-else 
    class="py-1 px-2 text-purple font-italic font-weight-bold custom-pre-component" 
    :class="[bgColor]"
  >
    {{data}}
  </div>
</template>

<script>
import JsonViewer from "vue-json-viewer";
import {copyText} from "@/custom/class/FunctionClasses/CommonToasts.js";

import {
  BButton
} from "bootstrap-vue";
export default {
  components: {
    BButton,
    JsonViewer
  },
  props: {
    data: {
      type: [Array, Object, Number, String, Boolean,],
      default: 'undefined',
    },
    maxHeight:{
      type: String,
      default: '300px'
    },
    minHeight:{
      type: String,
      default: '80px'
    },
    copyable:{
      type: Boolean,
      default: true
    },
    theme: {
      type: String,
      default: "custom-jv-dark"
    },
    bgColor:{
      type: String,
      default: 'bg-pre'
    }
  },
  computed: {
    parsedData() {
      let r = this.data

      if (r == 'undefined'){
        return undefined
      }
      try{
        r = JSON.parse(r)  
      } catch(err){}


      return r  
    }
  },
  methods: {
    copyDataToClipboard(){
      let data = structuredClone(this.data)
      if (typeof data != 'string'){
        data = JSON.stringify(data)
      }
      copyText(data)
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/json-viewer-themes.scss';
</style>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';

.bg-pre{
  background-color: $pre-bg;
}
div.custom-pre-container{
  border-radius: 5px;
  color: var(--light);
  position: relative;
}

.custom-pre-component{
  overflow: auto;
  max-width: 100%;
  border-radius: inherit;
  border-radius: 5px;
}

.pre-copy-btn{
  position: absolute;
  right: 10px;
  top: 0;
  padding: 10px 5px;
  font-size: 13px;
}
</style>