export default class Methods {
    constructor() {
        this.items = [
            {
                id: 1,
                label: "GET",
                abbreviation: "GET",
                script_output: "GET",
                variant: "light-success",
                btn_variant: "success"
            },
            {
                id: 2,
                label:"POST",
                abbreviation: "POS",
                script_output: "POST",
                variant: "light-warning",
                btn_variant: "warning"
            },
            {
                id: 3,
                label: "PUT",
                abbreviation: "PUT",
                script_output: "PUT",
                variant: "light-info",
                btn_variant: "info"
            },
            {
                id: 4,
                label: "DELETE",
                abbreviation: "DEL",
                script_output: "DELETE",
                variant: "light-danger",
                btn_variant: "danger"
            },
            {
                id: 5,
                label: "PATCH",
                abbreviation: "PAT",
                script_output: "PATCH",
                variant: "light-secondary",
                btn_variant: "secondary"
            },
        ];
    }
}