<template>
  <div 
    class="border-radius-5 overflow-hidden position-relative" 
    :style="`height: ${height};`" 
    :class="`json-editor-${variant}`"
  >
    <div class="json-custom-title">
      <slot name="title"></slot>
    </div>
    <div class="jsoneditor-vue"></div>
  </div>
</template>

<script>
import JsonEditor from "jsoneditor";
import "brace/theme/monokai";

export default {
  props: {
    value: [String, Number, Object, Array],
    expandedOnStart: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "code",
    },
    modes: {
      type: Array,
      default: () => { return ["tree", "code", "form", "view", "text", "preview" ]; }
    },
    height: {
      type: String,
      default: '250px'
    },
    variant:{
      type: String,
      default: 'default'
    },
    readOnly:{
      type: Boolean,
      default: false,
    }
  },
  computed: {
    jsonValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
  },
  data() {
    return {
      editor: null,
      error: false,
      json: this.value,
      internalChange: false,
      expandedModes: ["tree", "view", "form"],
    };
  },
  mounted() {
    let self = this;
    let options = {
      theme: "ace/theme/monokai",
      mode: this.mode,
      modes: this.modes, // allowed modes
      enableTransform: false,
      enableSort: false,
      editable:(()=>{
        return !this.readOnly
      }),
      onChange() {
        try {
          let json = self.editor.get();
          self.jsonValue = json;
          self.error = false;
          self.$emit("json-change", json);
          self.internalChange = true;
          self.$emit("input", json);
          self.$nextTick(function () {
            self.internalChange = false;
          });
        } catch (e) {
          self.error = true;
          self.$emit("has-error", e);
        }
      },
      onModeChange() {
        self.expandAll();
      },
      onValidationError(e) {
        self.$emit("invalidJson", e);
      }
    };

    this.editor = new JsonEditor(
      this.$el.querySelector(".jsoneditor-vue"),
      options,
      this.jsonValue
    );
  },
  watch: {
    jsonValue: {
      immediate: true,
      async handler(val) {
        if (!this.internalChange) {
          await this.setEditor(val);
          this.error = false;
          this.expandAll();
        }
      },
      deep: true,
    },
  },
  methods: {
    expandAll() {
      if (
        this.expandedOnStart &&
        this.expandedModes.includes(this.editor.getMode())
      ) {
        this.editor.expandAll();
      }
    },
    onSave() {
      this.$emit("json-save", this.jsonValue);
    },
    async setEditor(value) {
      if (this.editor) this.editor.set(value);
    },
  },
};
</script>

<style lang="scss" scoped>

.json-custom-title{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

}

.border-radius-5{
  border-radius: 5px;
}
</style>

<style lang="scss">
@import '@/assets/scss/custom-utils.scss';
@mixin editorStyle($editor-theme){

  .jsoneditor-vue{
    .jsoneditor-poweredBy{
      display: none;
    }
    height: 100%;
  }
  .ace-jsoneditor *,
  textarea.jsoneditor-text * {
    font-family: dejavu sans mono, droid sans mono, consolas, monaco,
      lucida console, courier new, courier, monospace, sans-serif !important;
  }

  .jsoneditor {
    border: none !important;

    .jsoneditor-menu {
      &:not(ul) {
        background-color: $editor-theme !important;
        border-bottom: 1px solid $editor-theme !important;
        border-radius: 5px 5px 0px 0px !important;
      }

      .jsoneditor-selected {
        background-color: $editor-theme !important;
      }
    }

    .jsoneditor-statusbar{
      display: none;
    }

    .ace_editor {
      border-bottom: none;
      border-top: none;
    }
  }
}


.json-editor-default{
  $default-theme: rgb(92, 92, 92);
  @include editorStyle($default-theme)
}
.json-editor-success{
  @include editorStyle($success)
}
.json-editor-info{
  @include editorStyle($info)
}
.json-editor-black{
  @include editorStyle($black)
}

</style>
