<template>
  <div>
    <div class="run-metadata-container">
      <div class="d-flex align-items-start justify-content-between d-flex">
        <h3>Run Metadata:</h3>
        <div class="small ">
          Executed: <span class="font-weight-bold">{{this.formataDate(run.created_at)}}</span>
        </div>
      </div>

      <div class="text-light d-flex flex-wrap justify-content-start align-items-start">

        <div class="border rounded p-1 w-50">
          <h5 class="mb-0 font-weight-bolder">Trigger Parameters:</h5>
          <hr class="mb-75 mt-50">
          <div class="d-flex align-items-center flex-wrap">
            <div v-for="(value, key, idx) in getGeneralMetadata()" :key="`metadata-field-${key}-${idx}-${uuid}`" class="mr-3">
              <span v-if="value && value.value">
                <b-icon v-if="value.icon" :icon="value.icon" class="mr-25" variant="light" scale="0.8"/>
                <!-- <b-icon v-else icon="square" class="mr-25 invisible" variant="light" scale="0.8"/> -->
                <span class="font-weight-bold">{{ key }}: </span>{{ value.value }}
              </span>
            </div>
          </div>
          <div class="px-50 mt-1 ">
            <div class="font-weight-bold received-body-title">
              Received data <span class="small text-secondary font-weight-bold">( $.DATA ) </span>:
            </div>
            <custom-pre :data="getTriggerData()" minHeight="200px" maxHeight="200px"  class="received-body-radius"/>
          </div>
        </div>
        <div class="pl-2 d-flex flex-wrap align-items-center justify-content-start w-50">

          <div class="px-50 w-100 mb-1">
            <div class="font-weight-bold received-body-title">
              Flux Return <span class="small text-secondary font-weight-bold">( $.RETURN ) </span>:
            </div>
            <custom-pre :data="getReturnBody()" minHeight="200px" maxHeight="200px" class="received-body-radius"/>
          </div>

          <div class="font-weight-bolder mr-1 border rounded px-1 py-50 mb-1">
            <b-icon icon="stopwatch" variant="" scale="1" class="mr-25 my-0"/>
            <span>
              Execution Time: <b class="text-info">{{ (run.time / (1000 * 1000)).toFixed(2) }}s</b>
            </span>
          </div>

          <div class="font-weight-bolder border rounded px-1 py-50 mr-1 mb-1">
            Run Status:             
            <span class="text-success" v-if="run.success">
              <b-icon icon="check" class="m-0 p-0" scale="1.4" variant="success"/>
              SUCCESS 
            </span>
            <span class="text-danger" v-else>
              <b-icon icon="x" class="m-0 p-0" scale="1.4" variant="danger"/>
              ERROR 
            </span>
          </div>
            <div class="font-weight-bolder border rounded px-1 py-50 mr-1 mb-1" v-if="log">
              Middlewares Executed:
              <span class="text-success">
                {{ log.middleware_executed }}
              </span>
            </div>

          <div class="font-weight-bolder mr-1 border rounded px-1 py-50 mb-1">
            <b-icon icon="card-heading" variant="" scale="1" class="mr-25"/>
            <span>
              Execution Message: <span :class="run.success ? 'text-success' : 'text-danger'">{{run.message}}</span>
            </span>
          </div>
        </div>
    
      </div>
    </div>

    <div v-if="!isTerminal">
      
      <div v-if="log && log.internal_interrupt" class="mb-2 rounded border-danger p-1 w-fit-content">
        <h3 class="text-danger mb-50 pb-50 text-center border-bottom-danger">
          <b-icon icon="exclamation-triangle-fill"/>
          <span class="mx-50">
            An Internal proccess has aborted this execution
          </span>
          <b-icon icon="exclamation-triangle-fill"/>
        </h3>
        <div class="ml-1 text-dark font-weight-bold">
          <b-icon icon="circle-fill" scale="0.4" variant="danger"/>
          The resources reserved to this execution have been exeeded and the run was aborted
        </div>
        <div class="ml-1 text-dark font-weight-bold">
          <b-icon icon="circle-fill" scale="0.4" variant="danger"/>
          A total of {{ log.middleware_executed }} Middlewares were executed
        </div>
      </div>

      <template v-if="log.has_finished">
        <template v-if="hasErrorLogs">
          <div v-for="errorType in errorTypes" :key="'error-tipe'+errorType.key" class="mb-2">
            
            <div :class="'border-left-' + errorType.variant" class="pl-50" v-if="Array.isArray(log.data[errorType.key]) && log.data[errorType.key].length > 0">
            
              <h4 class="font-weight-bolder">{{errorType.label}} Errors</h4>
              
              <div v-for="err in log.data[errorType.key]" class="font-size-15 mb-25">
                <span>

                  <b-badge :variant="'light-' + errorType.variant" class="mr-50">
                    <b-icon icon="exclamation-triangle" scale="1.2" class="mr-25"/>
                    {{ err.name }}
                  </b-badge>
                  <span class="small font-weight-bolder" v-if="getMiddlewareType(err.type)">{{ $t(`middleware.list.${getMiddlewareType(err.type).i18key}`).name }}</span>
                </span>
                
                <b-icon icon="arrow-right-short" class="mx-25" variant="secondary"/>
                
                <span class="font-weight-bold" :class="'border-bottom-'+errorType.variant">{{ err.message }}</span>
              </div>
              
            </div>
            
          </div>
        </template>
        <div v-else>
          <h4 class="text-success mx-1 my-2">
            <b-icon icon="check" scale="2" class="mr-1"/>
            Run finished without any errors
          </h4>
        </div>
      </template>
      <div v-else>
        <div class="my-3" align="center">
         <h3 class="text-favorite m-0">
          <b-icon icon="hourglass-split"/>
          RUN IS STILL EXECUTING . . . 
         </h3>
        </div>
      </div>

    </div>

    <div v-if="isTerminal || fullLogsFromScript">
      <template v-if="fullLogsFromScript">
        
        <template v-if="fullLogsFromScript.hits.hits[0]" >
          <div>
            <h3 class="mt-2 mb-0">Execution Logs:</h3>
          </div>
          <div class="text-secondary ml-50 mb-50">
            <b-icon icon="arrow-return-right"/> Detailed Logs expire 7 days after execution 
          </div>
          
          <run-logs
            :runLog="fullLogsFromScript.hits.hits[0]._source.data" 
            :wasSuccessfull="run.success" 
          />
        </template>

        <template v-else>
          <div class="text-secondary mt-2">
            Detailed Logs have expired 7 days after execution
          </div>
        </template>

      </template>

      <template v-else-if="run.json_return.logs">
        <div class="d-flex align-items-end justify-content-between flex-wrap">
          <h3 class="mt-2">Execution Logs:</h3>
        </div>
        
        <run-logs :runLog="run.json_return.logs" :wasSuccessfull="run.success"/>
      </template>
    </div>

    <div align="center" v-if="loadingScriptLogs && !isTerminal" class="my-1 py-1">
      <b-spinner variant="secondary"/>
      <h4 class="text-secondary mt-50 mb-0">
        Loading Logs...
      </h4>
    </div>
 
  </div>
</template>

<script>
import {
  BCard,
  BCollapse,
  BIcon,
  BBadge,
  BButton,
  BSkeleton,
  BSpinner,
} from "bootstrap-vue";
import moment from "moment";
import {copyText} from "@/custom/class/FunctionClasses/CommonToasts.js";
import RunLogs from "@/layouts/components/Terminal/TerminalTabs/Run/Refactored/RunLogs.vue"
import CustomPre from '@/views/pages/middleware/MiddlewareManagement/Components/CustomPre.vue'
import { v4 as uuidv4 } from 'uuid'
import Middlewares from "@/custom/class/Enum/Middlewares.js"

export default {
  components: {  
    BCard,
    BCollapse,
    BIcon,
    BBadge,
    BButton,
    RunLogs,
    BSkeleton,
    CustomPre,
    BSpinner,
  },
  data() {
    return {
      uuid: uuidv4(),
      errorTypes:[
        {
          label: 'Non Critical',
          key: 'errors',
          variant: 'warning'
        },
        {
          label: 'Critical',
          key: 'errors_critical',
          variant: 'danger'
        },
      ],
      fullLogsFromScript: undefined,
      loadingScriptLogs: true,
    }
  },
  props: {
    run: {
      type: Object,
      default: undefined 
    },
    log: {
      type: Object,
      default: undefined 
    },
    isTerminal: {
      type: Boolean,
      default: false,
    }
  },
  mounted () {
    this.init();
  },
  computed: {
    hasErrorLogs() {
      let d = this.log.data

      const nonCritical = Array.isArray(d.errors) && d.errors.length > 0
      const critical = Array.isArray(d.errors_critical) && d.errors_critical.length > 0

      return  (nonCritical || critical)
    }
  },
  methods: {
    init(){
      if (!this.isTerminal){ 
        this.$store.dispatch('fetchScriptLog',{transmissionID: this.$route.params.transmissionID, logId:this.log.unique_id})
        .then((resp)=>{
          this.fullLogsFromScript = resp
          this.loadingScriptLogs = false
        })
      }
    },
    formataDate(date) {
      date = new Date(date)
      return new moment(date).calendar()
    },
    getGeneralMetadata(){
    
      let r = {}
      const data = structuredClone(this.run)

      r['Sender'] = {value: data.sender, icon: "box-arrow-up-right"}
      r['Event'] = {value: data.event, icon: "broadcast"}
      r['Receiver'] = {value: data.receiver, icon: "box-arrow-in-down-right"}

      return r
    },
    copyText(text){
      if (typeof text != 'string'){
        text = JSON.stringify(text)
      }
      copyText(text)
    },
    getReturnBody(){
      return this.run.json_return?.info?.RETURN?.BODY 
    },
    getTriggerData(){
      return this.run.json_return?.info?.DATA
    },
    getMiddlewareType(id){
      console.log('sajamanta')
      let itms = new Middlewares().items
      console.log(id)
      console.log('%c'+JSON.stringify(itms.find(el => el.id == id)), 'color: limegreen');
      
      return itms.find(el => el.id == id)
    }
  },
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';

.font-size-15{
  font-size: 15px;
}
.received-body-radius{
  border-radius: 0 5px 5px 5px !important;
  border: 1px solid $pre-bg;
}
.received-body-title{
  background-color: $pre-bg ;
  width: fit-content;
  padding: 1px 8px;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid $dark;
}
</style>
