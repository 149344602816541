<template>
  <b-sidebar
    :id="uuid"
    bg-variant="white"
    header-class="darker-sidebar-header"
    left
    backdrop
    shadow
    class="agent-sidebar"
    width="40%"
    lazy
    @hidden="clearInfo"
    @shown="init"
  >
    <template #header>
      <div class="w-100 d-flex justify-content-between">
        <h2 class="ml-1 mt-50 sidebar-title text-uppercase">New Schedule</h2>

        <b-icon
          icon="x"
          scale="1.8"
          variant="secondary"
          class="cursor-pointer mt-50"
          @click="$emit('close')"
        />
      </div>
    </template>
    <template #default>
      <b-container fluid class="hide-overflow">
        <b-row>
          <b-col md=12>
          </b-col>
          <b-col class="mt-2">
          </b-col>
        </b-row>
        <b-row v-if="step1" class="animate-opt animate__animated animate__fadeInDown animate__faster">
          <b-col cols="12">
            <validation-observer ref="scheduler-name">
              <b-container fluid>
                <b-row class="mt-1">
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="scheduler name"
                      rules="required"
                    >
                      <label for="scheduler-name">{{ $t('scheduler.sidebar.identifier.label') }}</label>
                      <b-input-group>
                        <b-form-input
                          size="lg"
                          id="scheduler-name"
                          class="darker-input"
                          type="text"
                          v-model="scheduler.name"
                        />
                        <b-input-group-append 
                          is-text 
                          class="cursor-pointer"
                          @click="generateName"
                        >
                          <feather-icon
                            class="text-info"
                            icon="RefreshCwIcon"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row> 
                    
                <b-row class="mt-3">
                  <b-col md="6">
                    <div class="d-flex justify-content-between">
                      <label for="scheduler-start-date">{{ $t('scheduler.sidebar.start_date.label') }}</label>
                      <b-button class="py-0 px-50 small" variant="none" @click="putStartNow">
                        <span class="small text-secondary">Set to Now</span>
                      </b-button>
                    </div>
                    <flat-pickr
                      ref="scheduler-start-date"
                      id="scheduler-start-date"
                      autocomplete="off"
                      autofocus
                      required
                      v-model="scheduler.startDate"
                      class="form-control darker-input"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:S', altFormat: 'F j, Y - H:i:S',  maxDate: scheduler.endDate, allowInput: true, time_24hr: true, altInput: true	}"
                    />
                    <small v-if="dateError" class="text-danger">{{ $t('scheduler.sidebar.error.start_date_missing') }}</small>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      class="mb-0"
                    >
                      <b-form-checkbox
                        class="d-inline-block"
                        v-model="hasEndDate"
                        @click="resetField('endDate')"
                      />
                      <label for="scheduler-name">{{ $t('scheduler.sidebar.end_date.label') }}</label>
                      <flat-pickr
                        v-model="scheduler.endDate"
                        autocomplete="off"
                        autofocus
                        :disabled="!hasEndDate"
                        class="form-control d-inline-block end-date-input darker-input"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:S', altFormat: 'F j, Y - H:i:S', allowInput: true, minDate: scheduler.startDate, enableSeconds: true, time_24hr: true, altInput: true, }"
                      />
                    </b-form-group>
                    <small v-if="dateError && hasEndDate" class="text-danger">{{ $t('scheduler.sidebar.error.end_date_missing') }}</small>
                  </b-col>                    
                </b-row>
                <b-row class="mt-3">
                  <b-col md='12' class="text-center d-flex mt-50 align-content-center">
                    <hr class="w-100">
                    <b-icon icon="clock" variant="success" scale="1.5" class="mx-2 mt-50"/>
                    <hr class="w-100">
                  </b-col>
                </b-row>
                <b-row v-if="scheduleType" class="mt-1">
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="scheduler service"
                      rules="required"
                    >
                      <label for="service">{{ $t('scheduler.sidebar.service.label') }}</label>
                      <v-select
                        v-if="serviceOptions"
                        id="service"
                        v-model="scheduler.service"
                        :options="serviceOptions"
                        :reduce="(item) => item"
                        label="identifier"
                        @input="getEventByService"
                        :clearable="false"
                      >
                        <template #option="{ identifier }">
                          <span>{{ identifier }}</span>
                        </template>
                        <template slot="no-options"> {{$t("terminal_tests.terms.no_options")}} </template>
                      </v-select>
                      <b-skeleton v-else height="34px"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="scheduler event"
                      rules="required"
                    >
                      <label for="event">{{ $t('scheduler.sidebar.event.label') }}</label>
                      
                      <v-select
                        v-if="eventOptions"
                        id="event"
                        v-model="scheduler.event"
                        :options="eventOptions"
                        :reduce="(item) => item"
                        label="identifier"
                        @input="getEventByService"
                        :clearable="false"
                        :disabled="!scheduler.service"
                      >
                        <template #option="data">
                          <span>{{data.event.event_identifier}}</span>
                        </template>
                        <template #selected-option="data">
                          <span>{{data.event.event_identifier}}</span>
                        </template>
                        <template slot="no-options"> {{$t("terminal_tests.terms.no_options")}} </template>
                      </v-select>
                      <b-skeleton v-else height="34px"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md='12' class="text-center d-flex mt-3" >
                    <hr class="w-100">
                    <strong class="mx-1 mt-25">
                      {{ $t('scheduler.sidebar.dividers.interval') }}
                    </strong>
                    <hr class="w-100">
                  </b-col>
                </b-row>
                <b-row class="d-flex justify-content-between">
                  <b-col md="5" class="mt-1">
                    <label for="scheduler-days-interval">{{ $t('scheduler.sidebar.interval.label') }}</label>
                    <cleave
                      id="interval-input"
                      v-model="scheduler.minutesInterval"
                      class="form-control darker-input"
                      :disabled="hasNoInterval"
                      :raw="false"
                      :options="{
                        numericOnly: true,
                        blocks: [2, 2, 2, 0],
                        delimiters: ['d ', 'h ', 'm ']
                      }"
                    />
                  </b-col>
                  <b-col md="2" class="d-block justify-content-center">
                    <div class="text-center mt-1">
                      <span class="vertical-line"></span>
                    </div>
                    <div class="text-center my-50 text-uppercase">
                      or
                    </div>
                    <div class="text-center">
                      <span class="vertical-line"></span>
                    </div>
                  </b-col>
                  <b-col md="5">
                    <div class="mt-1 noselect">
                      <b-badge 
                        class="m-25 cursor-pointer" 
                        key="reset" 
                        variant="light-warning"
                        @click="noInterval"
                      >
                        {{ $t('scheduler.enum.interval.no_repeat') }} 
                      </b-badge>
                      <b-badge
                        class="m-25 cursor-pointer"
                        v-for="interval in intervalPresets"
                        :key="interval.id"
                        :variant="`light-${interval.variant}`"
                        @click="selectInterval(interval)"
                      >
                        {{ interval.value ? '+ ' + $t(interval.text) : $t(interval.text) }}
                      </b-badge>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </validation-observer>
          </b-col>
        </b-row>
        <b-row v-else-if="step2" class="animate-opt animate__animated animate__fadeInUp animate__faster">
          <b-col>
            <validation-observer ref="scheduler-webhook">
              <b-row>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="scheduler method"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('scheduler.sidebar.method.label')"
                      label-for="webhook-method"
                      class="mb-0"
                    >
                      <b-form-select
                        id="webhook-method"
                        :options="methods"

                        text-field="label"
                        value-field="id"

                        v-model="webhookInfo.method"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="scheduler content-type"
                    rules="required"
                  >
                    <label for="content-type">{{ $t('scheduler.sidebar.content_type.label') }}</label>
                    <v-select
                      id="content-type"
                      v-model="webhookInfo.contentType"
                      label="value"
                      :options="contentTypes"
                      :reduce="(item) => item"
                    >
                      <template #option="{ value }">
                        <span>{{ value }}</span>
                      </template>
                      <template slot="no-options"> {{$t("terminal_tests.terms.no_options")}} </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="12" class="mt-1">
                  <b-form-group
                    :label="$t('scheduler.sidebar.path.label')"
                    label-for="webhook-path"
                  >
                    <b-form-input
                      id="webhook-path"
                      placeholder="Server Path"
                      v-model="webhookInfo.path"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-tabs>
                    <b-tab :title="$t('scheduler.sidebar.headers.title')">
                      <b-row class="d-flex justify-content-center" v-for="(header, index) in headers" :key="`header-${index}`">
                        <b-col md="5">
                          <b-form-group
                            :label="$t('scheduler.sidebar.headers.key.label')"
                            label-for="webhook-method"
                          >
                            <b-form-input
                              id="headers-key"
                              :placeholder="$t('scheduler.sidebar.headers.key.placeholder')"
                              v-model="webhookInfo.headers[index].key"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group
                            :label="$t('scheduler.sidebar.headers.value.label')"
                            label-for="webhook-method"
                          >
                            <b-form-input
                              id="webhook-method"
                              :placeholder="$t('scheduler.sidebar.headers.value.placeholder')"
                              v-model="webhookInfo.headers[index].value"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="1" class="mt-2">
                          <b-button @click="deleteItem('headers', index)" class="px-1" variant="outline-danger">
                            <feather-icon
                              icon="TrashIcon"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-button size="sm" variant="primary" @click="addItem('headers')">
                            + {{ $t('scheduler.sidebar.headers.add_header') }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab title="Queries">
                      <b-row class="d-flex justify-content-center" v-for="(query, index) in queries" :key="`query-${index}`">
                        <b-col md="5">
                          <b-form-group
                            :label="$t('scheduler.sidebar.queries.key.label')"
                            label-for="querie-key"
                          >
                            <b-form-input
                              id="querie-key"
                              :placeholder="$t('scheduler.sidebar.queries.key.placeholder')"
                              v-model="webhookInfo.queries[index].key"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group
                            :label="$t('scheduler.sidebar.queries.value.label')"
                            label-for="querie-value"
                          >
                            <b-form-input
                              id="querie-value"
                              :placeholder="$t('scheduler.sidebar.queries.value.placeholder')"
                              v-model="webhookInfo.queries[index].value"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="1" class="mt-2">
                          <b-button 
                            class="px-1" 
                            variant="outline-danger"
                            @click="deleteItem('queries', index)"
                          >
                            <feather-icon
                              icon="TrashIcon"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-button size="sm" variant="primary" @click="addItem('queries')">
                            + {{ $t('scheduler.sidebar.queries.add_query') }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
            </validation-observer>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col md="12" class="animate-opt animate__animated animate__fadeInUp animate__faster">
            <b-form-group
              :label="$t('event.modal.input.label.json_object')"
              label-for="detect-json"
              class="w-100"
            >
              <json-editor id="detect-json" key="detect-json" v-model="scheduler.body" :modes="[ 'code', 'tree', 'preview' ]" height="450px" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template #footer>
      <b-row class="mb-75">
        <b-col md="12" class="d-flex justify-content-between">
          <b-button v-if="!step1" class="ml-1" variant="secondary" @click="step1 = true">
            <b-icon
              scale="0.8"
              icon="chevron-left"
            />
            <span>{{ $t("common.terms.back") }}</span>
          </b-button>
          <div v-else></div>
          <b-button
            variant="primary"
            class="mx-2"
            @click="submit"
            :disabled="isSaving"
          >
            <span v-if="isSaving">
              <b-spinner small></b-spinner>
              <span class="d-inline-block saving-adjust-text-position">
                {{ $t("common.terms.saving") }}
              </span>
            </span>
            <span v-else-if="finalStep">{{ $t("common.terms.save") }}</span>
            <span v-else>{{ $t("common.terms.next") }}
              <b-icon
                scale="0.8"
                icon="chevron-right"
              />
            </span>
          </b-button>
        </b-col>
      </b-row>

    </template>
  </b-sidebar>
</template>

<script>
import { 
  BIcon,
  BButton, 
  BContainer, 
  BCol, 
  BRow, 
  BSidebar,
  BFormGroup,
  BSpinner,
  BSkeleton,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormSelect,
  BTabs, 
  BTab,
  BFormCheckbox,
  BBadge,
  BCard
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";
import FlatPickr from 'vue-flatpickr-component';
import "flatpickr/dist/themes/dark.css";
import VSelect from "vue-select"
import Cleave from 'vue-cleave-component'
import 'animate.css';
import Methods from "@/custom/class/Enum/Methods";
import ScheduleInterval from '@/custom/class/Enum/ScheduleInterval'
import { makeToast } from "@/layouts/components/Popups.js"
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
import moment from 'moment'
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup.js';
import 'prismjs/components/prism-xml-doc.js';
import 'prismjs/components/prism-json.js';
import 'prismjs/themes/prism-tomorrow.css';
import { required } from 'vee-validate/dist/rules.umd.js'
import { extend } from 'vee-validate';
import { errorToast, successToast } from '@/custom/class/FunctionClasses/CommonToasts';

extend('required', required)

  export default {
    components: {
      BIcon,
      BButton, 
      BContainer, 
      BCol, 
      BRow, 
      BSidebar,
      BFormGroup,
      BSpinner,
      BSkeleton,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      ValidationObserver, 
      ValidationProvider,
      JsonEditor,
      FlatPickr,
      VSelect,
      Cleave,
      BFormSelect,
      BTabs, 
      BTab,
      PrismEditor,
      BFormCheckbox,
      BBadge,
      BCard
    },
    props: {
      uuid: {
        type: String,
        required: true,
      }
    },
    data() {
      return { 
        isSaving: false,
        dateError: false,

        timeMask: {
          time: true,
          timePattern: ['h', 'm'],
        },
        scheduler: {
          name: '',
          startDate: '',
          endDate: null, 
          event: null,
          service: null,
          webhook: null,
          body: null,
          minutesInterval: '000000',
        },
        scheduleType: true,
        eventOptions: null,
        serviceOptions: null,
        webhookOptions: null,
        webhookInfo: {
          method: null,
          contentType: null,
          path: '',
          queries: [
            { key: '', value: ''}
          ],
          headers: [
            { key: '', value: ''}
          ],
        },
        step1: true,
        step2: false,
        finalStep: false,
        hasEndDate: false,
        hasInterval: true,
        hasNoInterval: false,
        selectedInterval: null,
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      methods() {
        const methods = new Methods();
        return methods.items;
      },
      intervalPresets() {
        const intervals = new ScheduleInterval();
        return intervals.items;
      },
      queries() {
        return this.webhookInfo.queries
      },
      headers() {
        return this.webhookInfo.headers
      },
      contentTypes() {
        return [
          { key: 'content-type', value: 'application/json'},
          { key: 'content-type', value: 'application/x-www-form-urlencoded'},
          { key: 'content-type', value: 'application/xml'},
          { key: 'content-type', value: 'multipart/form-data'},
          { key: 'content-type', value: 'text/plain'},
        ]
      }
    },
    methods: {
      init() {
        this.generateName()
        this.getEventsByTransmission()
        this.getServicesByTransmission()
      },
      submit() {
        if (this.step1) {
          this.$refs['scheduler-name'].validate().then((success) => {
            if (this.scheduler.startDate && (this.scheduler.endDate || !this.hasEndDate)) {
              console.log('wa')
              
              let timeInterval = this.dayToMinute(this.scheduler.minutesInterval)
              if (!timeInterval || timeInterval < 1){
                errorToast({text: "Can't create a Scheduler with less than 1 minute interval"})
                success = false
              }

              this.dateError = false
              if (success) {
                this.step1 = false
                if (this.scheduleType) {
                  this.step2 = false
                  this.finalStep = true
                } else {
                  this.step2 = true
                }
              } 
            } else {
              this.dateError = true
            }
          })
        } else if (this.step2) {
          this.$refs['scheduler-webhook'].validate().then((success) => {
            if (success) {
              this.step1 = false
              this.step2 = false
              this.finalStep = true
            }
          })
        } else {
          this.scheduler.minutesInterval = this.dayToMinute(this.scheduler.minutesInterval)

          let payload = {
            name: this.scheduler.name,
            event:  this.scheduleType ? parseInt(this.scheduler.event.id) : null,
            service:  this.scheduleType ? parseInt(this.scheduler.service.id) : null,
            webhook:  !this.scheduleType && this.scheduler.webhook ? parseInt(this.scheduler.webhook.id) : null,
            enum_creation_origin: 2, // no webhook ou strin gvazia
            path: this.webhookInfo ? this.webhookInfo.path : '',
            body: this.scheduleType && this.scheduler.body ? JSON.stringify(this.scheduler.body) : this.scheduler.body  || '{}', // webhook ???
            enum_metodo: this.scheduleType ? 2 : parseInt(this.webhookInfo.method), // se event/service = post
            minutes_interval: this.scheduler.minutesInterval ? this.scheduler.minutesInterval : null,
            enum_time_metric: this.scheduler.minutesInterval == 0 ? 1 : 2,
            start_date: this.scheduler.startDate,
            end_date: this.hasEndDate ? this.scheduler.endDate : null,
          }
          
          this.$store.dispatch('insertScheduler', { transmissionID: this.$route.params.transmissionID, data: payload }).then((resp) => {
            if (!this.scheduleType) {
              this.webhookInfo.headers.push(this.webhookInfo.contentType) //* content-type pushed to header array here
              this.webhookInfo.headers.forEach((value) => {
                if (value.key && value.value) {
                  this.$store.dispatch('insertSchedulerHeader', { schedulerID: resp.data.id, item: value, transmissionID: this.$route.params.transmissionID }).then(() => {
                  }).catch((err) => {
                    console.error(err)
                    makeToast({
                      title: this.$t(`default_changer.toast.submit.error.title`),
                      text: this.$t(`default_changer.toast.submit.error.text`),
                      variant: "danger",
                      icon: "XIcon",
                    })
                  })
                }
              })
              this.webhookInfo.queries.forEach((value) => {
                if (value.key && value.value) {
                  this.$store.dispatch('insertSchedulerQuery', { schedulerID: resp.data.id, item: value, transmissionID: this.$route.params.transmissionID }).then(() => {
                    }).catch((err) => {
                      console.error(err)
                      makeToast({
                        title: this.$t(`default_changer.toast.submit.error.title`),
                      text: this.$t(`default_changer.toast.submit.error.text`),
                      variant: "danger",
                      icon: "XIcon",
                    })
                  })
                }
              })
            }
            this.$root.$emit(
              "bv::toggle::collapse",
              this.uuid
            );
            this.$router.push({ name: 'transmission-scheduler-view', params: { transmissionID: this.$route.params.transmissionID, schedulerID: resp.data.id } });
            makeToast({
              title: this.$t(`default_changer.toast.submit.success.title`),
              text: this.$t(`default_changer.toast.submit.success.text`),
              variant: "success",
              icon: "CheckIcon",
            })
          }).catch((err) => {
            console.error(err)
            makeToast({
              title: this.$t(`default_changer.toast.submit.error.title`),
              text: this.$t(`default_changer.toast.submit.error.text`),
              variant: "danger",
              icon: "XIcon",
            })
          })
        }
      },
      generateName() {
        this.$store.dispatch('customNameGenerator').then((resp) => {
          this.scheduler.name = resp
        })
      },
      toggleScheduleType() {
        if (this.scheduleType) {
          this.getServicesByTransmission()
          this.getEventsByTransmission()
        } else {
          this.getWebhookByTransmission()
        }
      },
      getEventByService() {
        this.$store
          .dispatch("getEventsByService", {
            transmissionID: this.$route.params.transmissionID,
            serviceId: this.scheduler.service.id,
          })
          .then((response) => {
            
            this.eventOptions = response.reduce((previous, current) => {
              if (current.can_send) {
                previous.push(current);
              }
              return previous;
            }, []);
          })
          .catch(() => {
            makeToast({
              title: this.$t(`default_changer.toast.submit.error.title`),
              text: this.$t(`default_changer.toast.submit.error.text`),
              variant: "danger",
              icon: "XIcon",
            });
          });
      },
      getServicesByEvent() {
        if (this.service) return
        this.$store
          .dispatch("getServicesByEvent", {
            transmissionID: this.$route.params.transmissionID,
            eventId: this.scheduler.event.id,
          })
          .then((response) => {
            this.serviceOptions = response.reduce((previous, current) => {
              if (current.can_receive) {
                previous.push(current);
              }
              return previous;
            }, []);
          })
          .catch((err) => {
            makeToast({
              title: this.$t(`default_changer.toast.submit.error.title`),
              text: this.$t(`default_changer.toast.submit.error.text`),
              variant: "danger",
              icon: "XIcon",
            });
          });
      },
      getServicesByTransmission() {
        this.resetOptions()
        this.$store
          .dispatch("getServicesByTransmissionGroup", {
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            this.serviceOptions = response.items || [];
          })
          .catch(() => {
            makeToast({
              title: this.$t(`default_changer.toast.submit.error.title`),
              text: this.$t(`default_changer.toast.submit.error.text`),
              variant: "danger",
              icon: "XIcon",
            });
          });
      },
      getEventsByTransmission() {
        this.resetOptions()
        this.$store
          .dispatch("fetchEventsByTransmissionGroup", {
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            this.eventOptions = response || [];
          })
          .catch(() => {
            makeToast({
              title: this.$t(`default_changer.toast.submit.error.title`),
              text: this.$t(`default_changer.toast.submit.error.text`),
              variant: "danger",
              icon: "XIcon",
            });
          });
      },
      getWebhookByTransmission() {
        this.resetOptions()
        this.$store
          .dispatch("getWebhookByTransmission", {
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            this.webhookOptions = response.items || [];
          })
          .catch((error) => {
            makeToast({
              title: this.$t(`default_changer.toast.submit.error.title`),
              text: this.$t(`default_changer.toast.submit.error.text`),
              variant: "danger",
              icon: "XIcon",
            });
          });
      },
      resetOptions() {
        this.webhookOptions = null
        this.eventOptions = null
        this.serviceOptions = null

        this.event = null 
        this.service = null
        this.webhook = null
      },
      onlyPositiveNumber(value) {
        if (value < 0) {
          return 0
        } 
        return value;
      },
      addItem(type) {
        this.webhookInfo[type].push({ key: '', value: ''})
      },
      deleteItem(type, key) {
        this.webhookInfo[type].splice(key, 1)
      },
      highlighter(code) {
        return highlight(code, languages.xml); // languages.<insert language> to return html with markup
      },
      clearInfo() {
        this.isSaving = false
        this.scheduler = {
          name: '',
          startDate: '',
          endDate: '', 
          event: null,
          service: null,
          webhook: null,
          body: null,
        },
        this.scheduleType = 1
        this.eventOptions = null
        this.serviceOptions = null
        this.webhookOptions = null
        this.minutesInterval = '0000'
        this.timeMask = {
          time: true,
          timePattern: ['h', 'm'],
        }
        this.webhookInfo = {
          method: null,
          path: '',
          queries: [
            { key: '', value: ''}
          ],
          headers: [
            { key: '', value: ''}
          ]
        }
        this.step1 = true
        this.step2 = false
        this.finalStep = false
      },
      resetField(field) {
        
        field = null
      },
      minuteToDay(value) {
        return (parseInt(value/24/60) > 9 ? parseInt(value/24/60) : '0' + parseInt(value/24/60)) 
          + '' + 
          (parseInt(value/60%24) > 9 ? parseInt(value/60%24) : '0' + parseInt(value/60%24)) 
          + '' + 
          (parseInt(value%60) > 9 ? parseInt(value%60) : '0' + parseInt(value%60));
      },
      dayToMinute(value) { 
        let days = parseInt(value.substring(0, 2))
        let hours = parseInt(value.substring(4, 6))
        let minutes =  parseInt(value.substring(8, 10))

        let totalMinutes = (days * 1440) + (hours * 60) + minutes
        return totalMinutes
      },
      selectInterval(item) {
        this.hasNoInterval = false
        if (item.value > 0) {
          let new_value = item.value + this.dayToMinute(this.scheduler.minutesInterval)
          this.scheduler.minutesInterval = this.minuteToDay(new_value)
          return
        }
        this.scheduler.minutesInterval = this.minuteToDay(item.value)
      },
      noInterval() {
        this.scheduler.minutesInterval = this.minuteToDay(0)
        this.hasNoInterval = true
      },
      putStartNow(){
        let now = moment(new Date()).format('Y-MM-DD HH:mm:ss')
        this.$set(this.scheduler, 'startDate', now)
      }
    }
  }
</script>

<style lang="scss" scoped>
// @import '@core/scss/vue/libs/vue-flatpicker.scss';
.hide-overflow {
  overflow: hidden !important;
}

.my-editor {
  background: #161d31;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;

  pre, textarea {
    background: none !important;
    border: none !important;
    outline: none !important;

    &:focus, &:active, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }
}

.height-500 {
  height: 500px;
}

.sidebar-title {
  font-weight: 550;
}

.vertical-line {
  border-left: 1px solid #3b4253;
  height: 100px !important;
}

.form-control:disabled {
  opacity: 0.2 !important;
}
</style>

<style lang="scss">

#event, #service, #webhook {
  .vs__dropdown-toggle {
    background-color: #0000001f !important;
  }
}
.darker-sidebar-header {
  background-color: #171c29 !important;
}

</style>