<template>
  <div>    
    <div v-if="logsByCategory">
      
      <div v-for="(dir) in Object.keys(logsByCategory)" :key="'logs-direction-'+dir" class="mb-2 direction-container" :class="directionDir[dir]">
        <template v-if="directionHasLogs(dir)">
        
          <h5 class="d-flex justify-content-between font-weight-bolder py-25 px-1 text-light w-fit-cosntent"> 
            <div>
              <b-icon v-if="dir == '$IN'" icon="box-arrow-in-down-right" class="mr-25"/>
              <b-icon v-if="dir == '$OUT'" icon="box-arrow-up-right" class="mr-25"/>
              {{ dir }}
            </div>
            <div class="d-flex">
              <b-button variant="none" class="py-0 px-50 mr-25" @click="toggleAllCats(dir , false)">
                <b-icon icon="fullscreen-exit" scale=".9" variant="black"/>          
              </b-button>
              <b-button variant="none" class="py-0 px-50"  @click="toggleAllCats(dir , true)">
                <b-icon icon="arrows-fullscreen" scale=".8" variant="black"/>          
              </b-button>
            </div>
          </h5>
          
          <div>

            <div v-for="cat in Object.keys(logsByCategory[dir])" :key="`logs-category-${cat}-${dir}`">
            
              <template v-if="logsByCategory[dir][cat] && logsByCategory[dir][cat].length">
                
                <div class="category-border-log" :class="cat">
                  
                  <div class="d-flex justify-content-between cursor-pointer" @click="visibility[`${dir}-${cat}`] = !visibility[`${dir}-${cat}`]">
                    <div :class="cat+'-title'" class="mb-50"> 
                      <span class="">{{categoryDic[cat].label}}</span>
                    </div>
                    <div>
                      <b-icon icon="chevron-down" scale="1.3" :variant="categoryDic[cat].variant" class="mx-1 transition-icon " :class="visibility[`${dir}-${cat}`] ? 'upside-down' : ''"/>
                    </div>
                  </div>
                    
                  <b-collapse :visible="visibility[`${dir}-${cat}`]">
                    <div class="px-50 pb-50 controll-overflow-scroll">
                      <run-log-list :logs="logsByCategory[dir][cat]" :isRoot="true"/>
                    </div>
                  </b-collapse>

                </div>
              </template>

            </div>
            
            <div class="py-25">
            </div>
          </div>

        </template>
      </div>
      
      <div v-if="!directionHasLogs('$IN') && !directionHasLogs('$OUT')">
        <div align="left" class="mx-3">
          <h4 class="text-secondary">No Middlewares in this Log.</h4>
        </div>
      </div>

      <div v-else-if="!wasSuccessfull" class="execution-aborted-sign"> 
        <b-icon icon="circle-fill" scale="0.2" class="mx-0"/>
        <b-icon icon="square-fill" scale="0.35" class="mx-0"/>
        <b-icon icon="circle-fill" scale="0.2" class="mx-0"/>
        
        <b-icon icon="exclamation-triangle" scale="1.2" class="mx-2"/>
        <span>EXECUTION ABORTED</span>
        <b-icon icon="exclamation-triangle" scale="1.2" class="mx-2"/>
        
        <b-icon icon="circle-fill" scale="0.2" class="mx-0"/>
        <b-icon icon="square-fill" scale="0.35" class="mx-0"/>
        <b-icon icon="circle-fill" scale="0.2" class="mx-0"/>
      </div>
    </div>
    
 </div>
</template>

<script>
import {
  BCard,
  BCollapse,
  BIcon,
  BBadge,
  BButton,
} from "bootstrap-vue";
import moment from "moment";
import {copyText} from "@/custom/class/FunctionClasses/CommonToasts.js";
import Middlewares from "@/custom/class/Enum/Middlewares.js"

import RunLogList from "@/layouts/components/Terminal/TerminalTabs/Run/Refactored/RunLogList.vue"

export default {
  components: {  
    BCard,
    BCollapse,
    BIcon,
    BBadge,
    BButton,
    RunLogList,
  },
  data() {
    return {
      logsByCategory: undefined,
      categoryDic:{
        "free": {label: "Free", variant: 'info'},
        "service": {label: "Service", variant: 'success'},
        "event": {label: "Event", variant: 'warning'},
        "service-event": {label: "Service + Event", variant: 'purple'},
      },
      directionDir:{
       "$IN": "dir-in",
       "$OUT": "dir-out"
      },


      visibility:{
        '$IN-free': true,
        '$IN-event': true,
        '$IN-service': true,
        '$IN-service-event': true,

        '$OUT-free': true,
        '$OUT-event': true,
        '$OUT-service': true,
        '$OUT-service-event': true,
      }
    }
  },
  props: {
    runLog: {
      type: [Object, Array],
      default: undefined 
    },
    wasSuccessfull: {
      type: Boolean,
      required: true 
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init(){
      this.divideLogsByCategory()
    },
    divideLogsByCategory(){
      //only has to work on the first layer

      let logs = structuredClone(this.runLog)
      
      let response = {
        '$IN':undefined,
         "$OUT":undefined
      }
      
      Object.keys(response).forEach((dir)=>{

        let r = {
          "free": [],
          "event": [],
          "service": [],
          "service-event": [],
        }
        if (Array.isArray(logs[dir])){

          logs[dir].forEach(log => {
            let cat = this.getCategory(log.service, log.event)
            r[cat].push(log)
          });
        }

        response[dir] = r
      })

  
      this.logsByCategory = response
    },
    formataDate(date) {
      date = new Date(date)
      return new moment(date).calendar()
    },
    copyText(text){
      if (typeof text != 'string'){
        text = JSON.stringify(text)
      }
      copyText(text)
    },
    middlewareType(enumId){
      return new Middlewares().items.find(el => el.id == enumId)
    },
    directionHasLogs(dir){
      const logs = structuredClone(this.logsByCategory)
      if (!logs){
        return
      }
      let r = false;
      Object.values(logs[dir]).forEach(arr => {
        if (arr && arr.length > 0){
          r = true
        }
      });
      return r;
    },
    getCategory(s, e){
      const sEmpty = s == "$NONE";
      const eEmpty = e == "$NONE";
      switch (true) {
        case (sEmpty && eEmpty) :
          return 'free'
        case (sEmpty && !eEmpty) :
          return 'event'
        case (!sEmpty && eEmpty) :
          return 'service'
        case (!sEmpty && !eEmpty) :
          return 'service-event'
      }
    },
    toggleAllCats(dir, state){
      const cats = ['free', 'event', 'service', 'service-event']
      cats.forEach(cat => {
        this.visibility[`${dir}-${cat}`] = state
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';


.execution-aborted-sign{
  background-color: transparentize($danger, 0.2s);
  margin-top: -20px;
  text-align: center;
  font-weight: bolder;
  letter-spacing: 2px;
  border-radius: 0 0 5px 5px;
  padding: 2px 5px;
  color: $black;
}

@mixin category-title($cat-color){
  width: fit-content;
  border-bottom-right-radius: 8px;
  padding: 2px 8px;
  border-bottom: 2px solid $cat-color;
  border-right: 2px solid $cat-color;
  background-color: transparentize($cat-color, .8) ;
  font-weight: bolder;
  color: $light; 
}

.controll-overflow-scroll{
  overflow: auto;
  max-width: 100%;
}

.transition-icon{
  transition: all 0.2s;
  &.upside-down{
    rotate: 180deg;
  }
}
.free-title{
  @include category-title($free);
}
.service-title{
  @include category-title($service);
}
.event-title{
  @include category-title($event);
}
.service-event-title{
  @include category-title($service-event);
}


@mixin direction-container($dir-color, $text-color, $bg-color){
  border-color: $dir-color;
  >h5{
    color: $text-color !important;

    background-color: $dir-color;
  }
  background-color: $bg-color;
}
.direction-container{
  border-radius: 3px;
  &.dir-in{
    @include direction-container($direction-in-color, $black, $direction-in-bg);
  }
  &.dir-out{
    @include direction-container($direction-out-color, $light, $direction-out-bg);
   
  }
  border-left: 3px solid;
  >div{
    padding: 0 4px;
  }
  >h5{
    border-radius: 0 4px 0px 0;
  }
}

.category-border-log{
  opacity: 0.8;
  &>*{
    opacity: initial;
  }
  border-top: 2px solid;
  border-left: 8px solid;
  //margin-bottom: 15px;
  //border-radius: 0 5px 5px 0;
  
  &.free{
    $cat-color: $info;
    //background-color: transparentize($cat-color,0.95);
    border-color: $cat-color;
  }
  &.service{
    $cat-color: $success;
    //background-color: transparentize($cat-color,0.95);
    border-color: $cat-color;
  }
  &.event{
    $cat-color: $event;
    //background-color: transparentize($cat-color,0.95);
    border-color: $cat-color;
  }
  &.service-event{
    $cat-color: $service-event;
    //background-color: transparentize($cat-color,0.95);
    border-color: $cat-color;
  }
}
</style>